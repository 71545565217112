import React, { useEffect, useMemo, useRef, useState } from 'react';
import { apiInspections } from "../api/inspections";
import { Loader } from 'google-maps';
import FSButton from "../components/Buttons/FSButton";
import * as PropTypes from "prop-types";
import { apiInspectors } from "../api/inspectors";
import { DateTime } from "luxon";
import { parseDateTimeAndChangeToLocalTimezone } from "../utilities/dateTime";
import { getRoles } from "../api/core/provider";

const formatDate = date => {
  const d = new Date(date);
  return d.toLocaleDateString();
};

const Planner = () => {

  function getInitials(InspectorName) {
    return InspectorName.split(" ").map((n) => n[0]).join("");
  }

  function InspectionsTable({ inspections, showingCompeleteds }) {
    return (
      <table className={"table planner-inspections-table tw-flex-grow-0 text-sm"}>
        <thead>
          <tr className={"tw-bg-pink-500 tw-bg-opacity-25 tw-rounded-t-lg"}>
            <th>Policy</th>
            <th>Insured</th>
            <th>Address</th>
            <th>Type</th>
            <th>Age</th>
            <th>Assign</th>
          </tr>
        </thead>
        <tbody className={'tw-text-sm'}>
          {inspections?.map((inspection) =>
            <tr key={inspection.PolicyNo}
              className={"odd:tw-bg-gray-200 hover:tw-bg-primary-light tw-transition tw-duration-200"}
              onMouseOver={() => mouseOverInspection(inspection)}
              onMouseOut={() => mouseOutInspection(inspection)}>
              <td><a target={"_blank"} href={`/inspections/${inspection.PolicyNo}/process/pre_r`} rel="noreferrer">{inspection.PolicyNo}</a></td>
              <td>{inspection.Insured}</td>
              <td>{inspection.Address}<br/>{inspection.City}<br/>{inspection.State}, {inspection.Zip}
              </td>
              <td>
                <p className={`tw-text-white tw-p-1 tw-w-8 tw-rounded tw-text-center`}
                  style={{ backgroundColor: getColorForInspection(inspection) }}>{inspection.Type}</p>
              </td>
              {showingCompeleteds ?
                <td>Compl. {inspection.CompletedAge} days ago</td> :
                <td>{inspection.Age} days old</td>
              }
              <td>{inspection.InspectorName && <>{getInitials(inspection.InspectorName)} <br/></>} {!inspection.AppointmentDate.includes("0001-01-01") && parseDateTimeAndChangeToLocalTimezone(inspection.AppointmentDate).toLocaleDateString(undefined,{ month:"short",day:"numeric" })}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  const isManager = getRoles().includes("manager");

  const [openInspections, setOpenInspections] = useState([]);
  const [google, setGoogle] = useState(null);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [mapInfoWindow, setMapInfoWindow] = useState(null);
  const [inspectionTypes, setInspectionTypes] = useState("Universal");
  const [selectedInspections, setSelectedInspections] = useState([]);
  const selectedInspectionsRef = useRef(selectedInspections);
  const mapInfoWindowRef = useRef(mapInfoWindow);
  const markersRef = useRef(markers);
  const [inspectors, setInspectors] = useState([]);
  const [selectedInspector, setSelectedInspector] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showNoAppointmentsOnly, setShowNoAppointmentsOnly] = useState(false);
  const showNoAppointmentsOnlyRef = useRef(showNoAppointmentsOnly);
  const [showRecentlyCompleted, setShowRecentlyCompleted] = useState(false);
  const showRecentlyCompletedRef = useRef(showRecentlyCompleted);
  const [showAges, setShowAges] = useState({ '10':true,'20':true,'30':true,'60':true });
  const [filteredInspector, setFilteredInspector] = useState(0);

  const [assigningInspections, setAssigningInspections] = useState(false);

  useEffect(() => {
    selectedInspectionsRef.current = selectedInspections;
  }, [selectedInspections]);

  useEffect(() => {
    mapInfoWindowRef.current = mapInfoWindow;
  }, [mapInfoWindow]);

  useEffect(() => {
    markersRef.current = markers;
  }, [markers]);

  useEffect(() => {
    showNoAppointmentsOnlyRef.current = showNoAppointmentsOnly;
  }, [showNoAppointmentsOnly]);

  useEffect(() => {
    showRecentlyCompletedRef.current = showRecentlyCompleted;
  }, [showRecentlyCompleted]);

  const mainDiv = useRef();

  const options = {/* todo */};
  const loader = new Loader('AIzaSyBHDVtQHIMXzOr7xjqadRZtScytVh__xfA', options);

  const moveSelectedDate = numberOfDays => {
    const date = new Date(selectedDate);
    date.setDate(date.getDate() + numberOfDays);
    setSelectedDate(date.toISOString().split("T")[0]);
  };

  const getColorForInspection = inspection => {

    // if (inspection.PolicyNo === "1501-2401-9630") {
    //   console.log(selectedInspectionsRef.current);
    // }
    if (selectedInspectionsRef.current.includes(inspection.PolicyNo)) {
      return "blue";
    }

    if (["completed","submitted"].includes(inspection.Status)) {
      return "#00abcd";
    }

    const age = inspection.Age;
    const ratio = Math.min(age / 90, 1); // Ensure the ratio does not exceed 1

    let r, g, b;

    if (age <= 10) {
      // Interpolate between green (0, 205, 0) and orange (255, 165, 0)
      const localRatio = age / 10;
      r = Math.floor(0 + localRatio * (255 - 0));
      g = Math.floor(205 - localRatio * (205 - 165));
      b = 0;
    } else if (age <= 20) {
      // Interpolate between orange (255, 165, 0) and red (255, 0, 0)
      const localRatio = (age - 10) / 10;
      r = 255;
      g = Math.floor(165 - localRatio * 165);
      b = 0;
    } else if (age <= 30) {
      // Interpolate between red (255, 0, 0) and purple (128, 0, 128)
      const localRatio = (age - 20) / 10;
      r = Math.floor(255 - localRatio * (255 - 128));
      g = 0;
      b = Math.floor(0 + localRatio * 128);
    } else {
      // Interpolate between purple (128, 0, 128) and black (0, 0, 0)
      const localRatio = (age - 30) / 60;
      r = Math.floor(128 - localRatio * 128);
      g = 0;
      b = Math.floor(128 - localRatio * 128);
    }

    return `rgb(${r},${g},${b})`;
  };

  const selectInspection = (policyNo) => {
    // policyNo is passed in instead of inspection because the dynamic html in the info window can't pass any object
    const inspection = filteredInspections.find(i => i.PolicyNo === policyNo);
    let added = false;
    if (selectedInspectionsRef.current.includes(inspection)) {
      setSelectedInspections(selectedInspectionsRef.current.filter(i => i !== inspection));
    } else {
      added = true;
      setSelectedInspections([...selectedInspectionsRef.current, inspection]);
    }
    mapInfoWindowRef.current.close();

    // update color of marker to either blue or original color
    const marker = markersRef.current.find(m => m.content.id === "ins-"+inspection.PolicyNo);
    if (marker) {
      const pin = document.getElementById(`ins-${inspection.PolicyNo}`);
      if (added) {
        pin.style.backgroundColor = "blue";
      } else {
        pin.style.backgroundColor = getColorForInspection(inspection);
      }
    }

  };


  const setMainDivSize = () => {
    if (!mainDiv.current) {
      return;
    }
    const height = window.innerHeight - mainDiv.current.offsetTop - 50;
    mainDiv.current.style.height = `${height}px`;
  };

  const initMap = async () => {
    let localGoogleVar = google;
    if (!localGoogleVar) {
      localGoogleVar = await loader.load();
      setGoogle(localGoogleVar);
    }
    const map = new localGoogleVar.maps.Map(document.getElementById('map'), {
      center: { lat: 25.782479, lng: -80.2706578 },
      zoom: 8,
      mapId: '8041c9b566fb264'
    });
    setMap(map);
    setMapInfoWindow(new localGoogleVar.maps.InfoWindow());

  };

  useEffect(() => {
    const initData = async () => {
      const inspectors = await apiInspectors.get();
      setInspectors(inspectors.data.result);
    };

    initData();
    initMap();
    window.onresize = () => {
      setMainDivSize();
    };
    setMainDivSize();
  }, []);

  useEffect(() => {
    updateMapBounds();
    setMainDivSize();
  }, [map, filteredInspections]);


  function ageFilter(inspection) {
    if (showRecentlyCompletedRef.current) {
      if (showAges['10'] && inspection.CompletedAge <= 10) {
        return true;
      }
      if (showAges['20'] && inspection.CompletedAge > 10 && inspection.CompletedAge <= 20) {
        return true;
      }
      if (showAges['30'] && inspection.CompletedAge > 20 && inspection.CompletedAge <= 30) {
        return true;
      }
      if (showAges['60'] && inspection.CompletedAge > 30) {
        return true;
      }
    } else {
      if (showAges['10'] && inspection.Age <= 10) {
        return true;
      }
      if (showAges['20'] && inspection.Age > 10 && inspection.Age <= 20) {
        return true;
      }
      if (showAges['30'] && inspection.Age > 20 && inspection.Age <= 30) {
        return true;
      }
      if (showAges['60'] && inspection.Age > 30) {
        return true;
      }
    }
    return false;
  }

  const updateInspections = async (noAppointments,showRecentlyCompleted) => {
    const inspections = (await apiInspections.getOpenInspectionsMap(inspectionTypes,showRecentlyCompleted)).data.result;
    // sort inspections by age - oldest first and then by zipcode
    inspections.sort((a, b) => {
      if (a.Age === b.Age) {
        return a.Zip - b.Zip;
      }
      return b.Age - a.Age;
    });

    setOpenInspections(inspections);
    setMarkers([]);
    initMap();
  };

  const filteredInspections = useMemo(() => {
    return openInspections.filter(i => !showNoAppointmentsOnly || i.AppointmentDate.includes("0001-01-01")).
      filter(i => ageFilter(i)).
      filter(i => filteredInspector===0 || i.InspectorID === Number(filteredInspector),
      );
  }, [openInspections,showNoAppointmentsOnly,showRecentlyCompleted,showAges,filteredInspector]);

  // useEffect(() => {
  //   updateInspections(showNoAppointmentsOnly);
  // }, []);

  useEffect(() => {
    // this also runs on page load
    updateInspections(showNoAppointmentsOnly,showRecentlyCompleted);
  }, [inspectionTypes,showAges,filteredInspector]);


  async function doCancelAppointment(policyNo, address) {
    if (!window.confirm(`Are you sure you want to cancel the appointment for the selected inspection - ${policyNo} / ${address}`)) {
      return;
    }
    await apiInspections.cancelAppointment(policyNo);
    updateInspections();
  }

  function createMarker(AdvancedMarkerElement, inspection) {

    const markerTag = document.createElement("div");

    markerTag.className = "inspection-map-tag";
    switch (inspection.Type) {
    case "A":
      markerTag.classList.add("inspection-map-tag-a");
      break;
    case "B":
    case "B+":
      markerTag.classList.add("inspection-map-tag-bplus");
      break;
    case "B++":
      markerTag.classList.add("inspection-map-tag-bplusplus");
      break;
    case "BR":
      markerTag.classList.add("inspection-map-tag-br");
      break;
    default:
      markerTag.classList.add("inspection-map-tag-other");
    }

    if (inspection.InspectionTime === "--") {
      markerTag.classList.add("inspection-map-tag-no-time");
    }

    markerTag.textContent = inspection.Type;
    markerTag.id = `ins-${inspection.PolicyNo}`;
    markerTag.style.backgroundColor = getColorForInspection(inspection);
    setAfterProperty(markerTag, "border-top-color", inspection.Type==="UE" ? "green" : "yellow");

    // font-size: 12px;
    //     padding: 8px 8px;
    markerTag.style.padding = "8px 8px";
    markerTag.style.fontSize = "0.8rem";

    const marker = new AdvancedMarkerElement({
      map,
      position: { lat: inspection.Lat, lng: inspection.Lng },
      content: markerTag,
    });

    marker.addListener("click", () => {

      if (selectedInspectionsRef.current.length > 0) {
        selectInspection(inspection.PolicyNo);
        return;
      }

      window.selectInspectionFunc = selectInspection;

      let content = "";
      content = content + `<div>`;
      content = content + `<p class="tw-text-xl"><a class="" target="_blank" href="/inspections/${inspection.PolicyNo}/process/pre_r"}><strong class="tw-text-primary-500">${inspection.PolicyNo}</strong></a></p>`;
      content = content + `<p>${inspection.Insured}</p>`;
      content = content + `<p>${inspection.Address}<br/>${inspection.City}, ${inspection.County}, ${inspection.State} ${inspection.Zip}</p>`;
      content = content + `<br/`;
      content = content + `<p>Inspection Type: ${inspection.Type}</p>`;
      content = content + `<p>${inspection.Age} days old</p>`;
      content = content + `<br/`;
      content = content + `<p>Status: <strong>${inspection.PrettyStatus}</strong></p>`;
      content = content + `<p>Assigned to: ${inspection.InspectorName ? inspection.InspectorName : "n/a"}</p>`;
      content = content + `<p>Appointment Date: ${!inspection.AppointmentDate.includes("0001-01-01") ? parseDateTimeAndChangeToLocalTimezone(inspection.AppointmentDate).toLocaleDateString(undefined,{ month: 'short', day: 'numeric' }):"n/a"}</p>`;
      content = content + `<div class="tw-mt-4 tw-flex tw-justify-cente tw-gap-4">`;
      content = content + `<button class="tw-text-white tw-px-4 tw-py-2 tw-bg-primary-500 tw-uppercase" onclick="selectInspectionFunc('${inspection.PolicyNo}')">Select</button>`;
      if (isManager) {
        content = content + `<button class="tw-text-white tw-px-4 tw-py-2 tw-bg-primary-500 tw-uppercase" onclick="window.doCancelAppointmentFunc('${inspection.PolicyNo}','${inspection.Address}')">Cancel Appt.</button>`;
      }
      content = content + `</div>`;

      // can't believe this hack actually works - call react function from plain js
      // window.setAppointmentFunc = setAppointment;
      // window.selectInspectionFunc = selectInspection;
      window.doCancelAppointmentFunc = doCancelAppointment;

      if (mapInfoWindowRef.current) {
        mapInfoWindowRef.current.setContent(content);
        mapInfoWindowRef.current.open({ anchor: marker, map });
      } else {
        console.error("mapInfoWindow not set");
      }
    });

    return marker;

    // const color = getColorForInspection(inspection);
    // const pinConfig = {
    //   background: color,
    //   borderColor: color,
    //   glyphColor: "white"
    // };
    //
    // const pin = new PinElement(pinConfig);
    // return pin;

  }

  // Function to set the ::after property of an element
  function setAfterProperty(element, property, value) {
    const styleSheet = document.styleSheets[0];
    const selector = `#${element.id}::after`;
    const rule = `${property}: ${value};`;

    // Check if the rule already exists
    let ruleIndex = -1;
    for (let i = 0; i < styleSheet.cssRules.length; i++) {
      if (styleSheet.cssRules[i].selectorText === selector) {
        ruleIndex = i;
        break;
      }
    }

    // If the rule exists, update it; otherwise, insert a new rule
    if (ruleIndex !== -1) {
      styleSheet.cssRules[ruleIndex].style[property] = value;
    } else {
      styleSheet.insertRule(`${selector} { ${rule} }`, styleSheet.cssRules.length);
    }
  }

  const updateMapBounds = async () => {
    if (!map) {
      return;
    }
    markersRef.current.forEach(marker => marker.setMap(null));
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    const markerList = [];
    if (map && openInspections) {
      const bounds = new window.google.maps.LatLngBounds();
      // reverse order to show oldest first so that the newest pins are on top
      const reversedInspections = filteredInspections.slice().reverse();
      for (const inspection of reversedInspections) {
        // const marker = new AdvancedMarkerElement({
        //   map,
        //   position: { lat: inspection.lat, lng: inspection.lng },
        //   content: createPin(PinElement, inspection).element
        // });
        const marker = createMarker(AdvancedMarkerElement, inspection);
        markerList.push(marker);
        bounds.extend(marker.position);
      }
      map.fitBounds(bounds);
      setMarkers(markerList);
    }
  };

  function mouseOverInspection(inspection) {
    // make map pin expand
    const pin = document.getElementById(`ins-${inspection.PolicyNo}`);
    if (pin) {
      // pin.style.width = "2rem";
      // pin.style.height = "2rem";
      pin.style.fontSize = "1.4rem";
      pin.style.padding = "0.9rem";
      pin.style.border = "2px solid black";
      pin.style.boxShadow = "0 0 15px 10px rgba(0, 0, 0, 0.3)";
      pin.style.zIndex = "1000";
    }
  }

  function mouseOutInspection(inspection) {
    // make map pin shrink
    const pin = document.getElementById(`ins-${inspection.PolicyNo}`);
    if (pin) {
      pin.style.padding = "8px 8px";
      pin.style.fontSize = "0.8rem";
      pin.style.border = "none";
      pin.style.boxShadow = "none";
      pin.style.zIndex = "0";
    }
  }

  const assignClick = async () => {
    // confirm assignment
    if (!(selectedInspector || selectedDate)) {
      alert("Please select an inspector and / or date");
      return;
    }
    const inspectorName = inspectors.find(i => i.id === Number(selectedInspector))?.name;
    if (selectedDate && inspectorName) {
      if (!confirm(`Assign ${selectedInspections.length} inspections to ${inspectorName} with appointment on ${selectedDate}?`)) {
        return;
      }
    } else if (selectedDate && !inspectorName) {
      if (!confirm(`Set appointment for ${selectedInspections.length} inspections on ${selectedDate}?`)) {
        return;
      }
    } else {
      if (!confirm(`Assign ${selectedInspections.length} inspections to ${inspectorName}?`)) {
        return;
      }
    }

    setAssigningInspections(true);
    await apiInspections.assignInspectionsFromMap(Number(selectedInspector), selectedDate, selectedInspections.map(i => i.PolicyNo));
    clearSelection();
    updateInspections(updateInspections,showRecentlyCompleted);
    setAssigningInspections(false);
  };

  const unAssignClick = async () => {
    // confirm assignment
    if (!confirm(`Unassign ${selectedInspections.length} inspections?`)) {
      return;
    }

    setAssigningInspections(true);
    await apiInspections.unAssignInspectionsFromMap(selectedInspections.map(i => i.PolicyNo));
    clearSelection();
    updateInspections(updateInspections,showRecentlyCompleted);
    setAssigningInspections(false);
  };

  const cancelApptClick = async () => {
    if (!confirm(`Cancel ${selectedInspections.length} appointments?`)) {
      return;
    }

    setAssigningInspections(true);
    await apiInspections.cancelAppointments(selectedInspections.map(i => i.PolicyNo));
    clearSelection();
    updateInspections(updateInspections,showRecentlyCompleted);
    setAssigningInspections(false);
  };

  const unAssignAndCancelClick = async () => {
    if (!confirm(`Unassign and cancel appointment for ${selectedInspections.length} inspections?`)) {
      return;
    }

    setAssigningInspections(true);
    await apiInspections.unAssignInspectionsFromMap(selectedInspections.map(i => i.PolicyNo));
    await apiInspections.cancelAppointments(selectedInspections.map(i => i.PolicyNo));
    clearSelection();
    updateInspections(updateInspections,showRecentlyCompleted);
    setAssigningInspections(false);
  };

  // const sortedInspections = openInspections?.toSorted((a,b) => {
  //   // sort by age, then by zip
  //   // put any selected inspection at the top, maintaining the age and zip
  //   if (selectedInspectionsRef.current.includes(a) && !selectedInspectionsRef.current.includes(b)) {
  //     return -1;
  //   }
  //   if (!selectedInspectionsRef.current.includes(a) && selectedInspectionsRef.current.includes(b)) {
  //     return 1;
  //   }
  //
  //   if (a.Age === b.Age) {
  //     return a.Zip - b.Zip;
  //   }
  //   return b.Age - a.Age;
  //
  // });

  const clearSelection = () => {
    // call select inspection on each selected to clear the map coloring
    selectedInspections.forEach((i) => selectInspection(i.PolicyNo));
    // then clear the main list
    setSelectedInspections([]);
  };

  function noAppointmentsCheckClick(value) {
    setShowNoAppointmentsOnly(value);
    updateInspections(value,showRecentlyCompleted);
  }

  function recentlyCompletedCheckClick(value) {
    setShowRecentlyCompleted(value);
    updateInspections(showNoAppointmentsOnly,value);
  }

  function getLabelForAgeCheck(age) {
    switch (age) {
    case '10':
      return "0-10 days";
    case '20':
      return "11-20 days";
    case '30':
      return "21-30 days";
    case '60':
      return "31+ days";
    }
  }

  const ageFilterItems = useMemo(() => {
    if (showRecentlyCompleted) {
      return ['10','20','30'];
    }
    return ['10','20','30','60'];
  },[showRecentlyCompleted]);

  const inspectorsWithInspections = inspectors.filter((i) => {
    for (const inspection of openInspections) {
      if (inspection.InspectorID === i.id) {
        return true;
      }
    }
  });

  return (
    <div>
      <h1>Global Open Inspections</h1>

      <div className={"tw-flex tw-flex-wrap tw-gap-8 tw-mb-4 tw-items-baseline"}>
        <label>Inspection Types:</label>
        <div className={"tw-flex tw-gap-4 [&_*]:tw-p-2"}>
          <label>
            <input className="tw-ml-4" type={"radio"} name="type" value={"Orchid"}
              checked={inspectionTypes === "Orchid"} onChange={() => setInspectionTypes("Orchid")}/>
            Orchid
          </label>
          <label>
            <input type={"radio"} name="type" value={"Universal"} checked={inspectionTypes === "Universal"}
              onChange={() => setInspectionTypes("Universal")}/>
            Universal
          </label>
          <label>
            <input type={"radio"} name="type" value={"All"} checked={inspectionTypes === "All"}
              onChange={() => setInspectionTypes("All")}/>
            All
          </label>
        </div>
        <div className={"tw-flex tw-gap-8"}>
          <label>
            <input type={"checkbox"} value={showNoAppointmentsOnly}
              onClick={(e) => noAppointmentsCheckClick(e.target.checked)}/>
            No appointments only
          </label>
          <label>
            <input type={"checkbox"} value={showRecentlyCompleted}
              onClick={(e) => recentlyCompletedCheckClick(e.target.checked)}/>
            Show recently completed
          </label>
        </div>
        <div className={"tw-flex tw-gap-8"}>
          {ageFilterItems.map((age) =>
            <label key={age}>
              <input type={"checkbox"} checked={showAges[age]}
                onClick={(e) => setShowAges({ ...showAges,[age]:e.target.checked })}/>
              {getLabelForAgeCheck(age)}
            </label>
          )
          }
        </div>
        <div>
          <label>Filter by Inspector
            <select className={""} value={filteredInspector} onChange={e => setFilteredInspector(Number(e.target.value))}>
              <option value={0}>All</option>
              {inspectorsWithInspections.map((inspector) =>
                <option key={inspector.id} value={inspector.id}>{inspector.name}</option>
              )}
            </select>
          </label>
        </div>
      </div>

      <div ref={mainDiv}
        // className={`${openInspections?.length > 0 ? "xl:tw-grid tw-grid-cols-3 xl:tw-gap-4" : "tw-hidden"} tw-my-4`}>
        className={`xl:tw-grid tw-grid-cols-3 xl:tw-gap-4 tw-my-4`}>
        <div className={"tw-overflow-y-scroll"}>
          {selectedInspections.length > 0 &&
              <div className={"tw-mb-4"}>
                <div className={"tw-flex tw-items-center tw-gap-8"}>
                  <p className={"tw-text-xl"}>Selected {selectedInspections.length === 1 ? "inspection" : "inspections"} - {selectedInspections.length}</p>
                  <FSButton onClick={clearSelection}>clear</FSButton>
                </div>
                <InspectionsTable inspections={selectedInspections} showingCompeleteds={showRecentlyCompleted}/>

                <div className={"tw-flex tw-items-center tw-justify-between tw-gap-4"}>
                  <div className={"tw-flex tw-gap-2 tw-items-baseline"}>
                    <input className="tw-rounded-lg" type="date" value={selectedDate}
                      onChange={e => setSelectedDate(e.target.value)}/>
                  </div>
                  <select className="tw-rounded-lg tw-flex-grow" onChange={e => setSelectedInspector(e.target.value)}>
                    <option value="0">Select Inspector</option>
                    {inspectors.map((inspector) =>
                      <option key={inspector.id} value={inspector.id}>{inspector.name}</option>
                    )}
                  </select>
                  <FSButton disabled={!(selectedInspector>0 || selectedDate)} submitting={assigningInspections} onClick={assignClick}>Apply</FSButton>
                </div>
                <h2>Actions</h2>
                <div className={"tw-flex tw-justify-between"}>
                  <FSButton submitting={assigningInspections} onClick={unAssignClick}>Unassign</FSButton>
                  <FSButton submitting={assigningInspections} onClick={cancelApptClick}>Cancel Appt.</FSButton>
                  <FSButton submitting={assigningInspections} onClick={unAssignAndCancelClick}>Unassign & Cancel Appt.</FSButton>
                </div>

              </div>
          }

          <p className={"tw-text-xl"}>Displayed Inspections - {filteredInspections?.length}</p>
          <InspectionsTable inspections={filteredInspections} showingCompeleteds={showRecentlyCompleted}/>
        </div>
        <div id={"map"}
          className={`tw-my-4 2xl:tw-my-0 tw-shadow tw-overflow-hidden tw-rounded-xl tw-w-full tw-col-span-2 tw-max-h-screen`}>
        </div>
      </div>

    </div>
  );

};

export default Planner;